import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
};

export const getAllLeads = createAsyncThunk('lead/getAllLeads', async (filter, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/leads/list?sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`, {});
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getLeadStatusNotes = createAsyncThunk('lead/getLeadStatusNotes', async ({ assignmentRef, empId, leadStatusType }, { rejectWithValue }) => {
    const reqBody = leadStatusType.length ? {
        leadStatusType: leadStatusType
    } : {}
    try {
        const response = await axiosInstance.post(`/lead-status-notes/list/${assignmentRef}/${empId}`, reqBody);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const assignLead = createAsyncThunk('lead/assignLead', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/assign-profile/create`, data);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const updateLead = createAsyncThunk('lead/updateLead', async ({ id, status }, { rejectWithValue }) => {
    const reqBody = {
        status: status
    }
    try {
        const response = await axiosInstance.post(`/leads/update/${id}`, reqBody);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const createLeadStatusNotes = createAsyncThunk('lead/createLeadStatusNotes', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/lead-status-notes/create`, data);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const leadSlice = createSlice({
    name: 'lead',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllLeads.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllLeads.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getAllLeads.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            });
        // .addCase(updateLead.pending, (state) => {
        //     state.loading = true;
        // })
        // .addCase(updateLead.fulfilled, (state) => {
        //     state.loading = false;
        // })
        // .addCase(updateLead.rejected, (state) => {
        //     state.message = action.payload;
        //     state.showMessage = true;
        //     state.loading = false;
        // });
    },
});

export const { showLoading } = leadSlice.actions;

export default leadSlice.reducer;
