import { createAsyncThunk, createSlice, isDraft } from "@reduxjs/toolkit";
import axiosInstance from 'api/axios';
import * as payloadMergers from '../mergers/index.js'

export const initialState = {
    loading: false,
    message: "",
    showMessage: ""
}

export const getPlanList = createAsyncThunk('subscription/getPlanList', async (filter, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/subscriptions/list?sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}&page=${filter.page}&pageSize=${filter.pageSize}`, {
            isActive: filter.isActive,
            searchQuery: filter.searchQuery
        });
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const deletePlan = createAsyncThunk('subscription/deletePlan', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.delete(`/subscriptions/delete/${data}`);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const updatePlan = createAsyncThunk('subscription/updatePlan', async ({ id, data }, { rejectWithValue }) => {
    try {
        const mergedPayload = await payloadMergers.mergeAddPlanRequestObject(data);
        const response = await axiosInstance.put(`/subscriptions/update/${id}`, mergedPayload);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    }
    catch (err) {
        return rejectWithValue(err.message || 'Error')
    }
})

export const createPlan = createAsyncThunk('subscription/createPlan', async (data, { rejectWithValue }) => {
    try {
        const mergedPayload = await payloadMergers.mergeAddPlanRequestObject(data);
        const response = await axiosInstance.post(`/subscriptions/create`, mergedPayload);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    }
    catch (err) {
        return rejectWithValue(err.message || 'Error')
    }
})

export const getPlanDetailById = createAsyncThunk('subscription/getPlanDetailById', async (id, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`/subscriptions/${id}?isFrontend=true`);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    }
    catch (err) {
        return rejectWithValue(err.message || 'Error')
    }
})

export const assignUserSubscription = createAsyncThunk('subscription/assignUserSubscription', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post(`/user-subscription/create`, data);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    }
    catch (err) {
        let message = err?.response?.data?.message;
        return rejectWithValue(message || 'Error');
    }
})

export const updateUserSubscription = createAsyncThunk('subscription/updateUserSubscription', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.put(`/user-subscription/update/${id}`, data);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    }
    catch (err) {
        let message = err?.response?.data?.message;
        return rejectWithValue(message || 'Error');
    }
})

export const getSubscribedProfiles = createAsyncThunk('subscription/getSubscribedProfiles', async ({ data, filter }, { rejectWithValue }) => {
    console.log(filter, "115")
    try {
        const response = await axiosInstance.post(`/user-subscription/list?sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}&page=${filter.page}&pageSize=${filter.pageSize}`, data)
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getUserPlanDetail = createAsyncThunk('subscription/getUserPlanDetail', async (id, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`/user-subscription/${id}?isFrontend=true`);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    }
    catch (err) {
        return rejectWithValue(err.message || 'Error')
    }
})

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPlanDetailById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPlanDetailById.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getPlanDetailById.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(createPlan.pending, (state) => {
                state.loading = true;
            })
            .addCase(createPlan.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(createPlan.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(updatePlan.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePlan.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updatePlan.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(deletePlan.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePlan.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deletePlan.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getPlanList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPlanList.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getPlanList.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
    }
})

export const { showLoading } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;